import { createElement, useContext } from "react";
import { FluentContext } from "./context.js";
export function withLocalization(Inner) {
    function WithLocalization(props) {
        const l10n = useContext(FluentContext);
        if (!l10n) {
            throw new Error("withLocalization was used without wrapping it in a " +
                "<LocalizationProvider />.");
        }
        // Re-bind getString to trigger a re-render of Inner.
        const getString = l10n.getString.bind(l10n);
        return createElement(Inner, { getString, ...props });
    }
    WithLocalization.displayName = `WithLocalization(${displayName(Inner)})`;
    return WithLocalization;
}
function displayName(component) {
    return component.displayName || component.name || "Component";
}
