import React, { isValidElement, useContext, } from "react";
import { FluentContext } from "./context.js";
/**
 * The `Localized` class renders its child with translated props and children.
 *
 * The `id` prop should be the unique identifier of the translation.  Any
 * attributes found in the translation will be applied to the wrapped element.
 *
 * Arguments to the translation can be passed as `$`-prefixed props on
 * `Localized`.
 *
 * It's recommended that the contents of the wrapped component be a string
 * expression.  The string will be used as the ultimate fallback if no
 * translation is available.  It also makes it easy to grep for strings in the
 * source code.
 *
 * @example
 * ```jsx
 * <Localized id="hello-world">
 *     <p>{'Hello, world!'}</p>
 * </Localized>
 *
 * <Localized id="hello-world" $username={name}>
 *     <p>{'Hello, { $username }!'}</p>
 * </Localized>
 * ```
 */
export function Localized(props) {
    const { id, attrs, vars, elems, nestedElems, children } = props;
    const l10n = useContext(FluentContext);
    if (!l10n) {
        throw new Error("The <Localized /> component was not properly wrapped in a <LocalizationProvider />.");
    }
    let source;
    if (Array.isArray(children)) {
        if (children.length > 1) {
            throw new Error("Expected to receive a single React element to localize.");
        }
        // If it's an array with zero or one element, we can directly get the first one.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        source = children[0];
    }
    else {
        source = children !== null && children !== void 0 ? children : null;
    }
    // Check if the component to render is a valid element -- if not, then
    // it's either null or a simple fallback string. No need to localize the
    // attributes or replace.
    if (!isValidElement(source)) {
        const fallback = typeof source === "string" ? source : undefined;
        const string = l10n.getString(id, vars, fallback);
        return React.createElement(React.Fragment, null, string);
    }
    return l10n.getElement(source, id, { attrs, vars, elems, nestedElems });
}
export default Localized;
