import { __assign, __awaiter, __generator } from "tslib";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { getSkewCorrectedDate } from "./utils/getSkewCorrectedDate";
import { getUpdatedSystemClockOffset } from "./utils/getUpdatedSystemClockOffset";
export var awsAuthMiddleware = function (options) {
    return function (next, context) {
        return function (args) {
            return __awaiter(this, void 0, void 0, function () {
                var signer, output, _a, _b, headers, dateHeader;
                var _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!HttpRequest.isInstance(args.request))
                                return [2, next(args)];
                            return [4, options.signer()];
                        case 1:
                            signer = _d.sent();
                            _a = next;
                            _b = [__assign({}, args)];
                            _c = {};
                            return [4, signer.sign(args.request, {
                                    signingDate: getSkewCorrectedDate(options.systemClockOffset),
                                    signingRegion: context["signing_region"],
                                    signingService: context["signing_service"],
                                })];
                        case 2: return [4, _a.apply(void 0, [__assign.apply(void 0, _b.concat([(_c.request = _d.sent(), _c)]))]).catch(function (error) {
                                if (error.ServerTime) {
                                    options.systemClockOffset = getUpdatedSystemClockOffset(error.ServerTime, options.systemClockOffset);
                                }
                                throw error;
                            })];
                        case 3:
                            output = _d.sent();
                            headers = output.response.headers;
                            dateHeader = headers && (headers.date || headers.Date);
                            if (dateHeader) {
                                options.systemClockOffset = getUpdatedSystemClockOffset(dateHeader, options.systemClockOffset);
                            }
                            return [2, output];
                    }
                });
            });
        };
    };
};
export var awsAuthMiddlewareOptions = {
    name: "awsAuthMiddleware",
    tags: ["SIGNATURE", "AWSAUTH"],
    relation: "after",
    toMiddleware: "retryMiddleware",
    override: true,
};
export var getAwsAuthPlugin = function (options) { return ({
    applyToStack: function (clientStack) {
        clientStack.addRelativeTo(awsAuthMiddleware(options), awsAuthMiddlewareOptions);
    },
}); };
export var getSigV4AuthPlugin = getAwsAuthPlugin;
