import { booleanSelector, SelectorType } from "@aws-sdk/util-config-provider";
export var NODE_USE_ARN_REGION_ENV_NAME = "AWS_S3_USE_ARN_REGION";
export var NODE_USE_ARN_REGION_INI_NAME = "s3_use_arn_region";
export var NODE_USE_ARN_REGION_CONFIG_OPTIONS = {
    environmentVariableSelector: function (env) {
        return booleanSelector(env, NODE_USE_ARN_REGION_ENV_NAME, SelectorType.ENV);
    },
    configFileSelector: function (profile) { return booleanSelector(profile, NODE_USE_ARN_REGION_INI_NAME, SelectorType.CONFIG); },
    default: false,
};
