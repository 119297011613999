import { __assign } from "tslib";
import { getEndpointFromRegion } from "./utils/getEndpointFromRegion";
import { normalizeBoolean } from "./utils/normalizeBoolean";
import { normalizeEndpoint } from "./utils/normalizeEndpoint";
export var resolveEndpointsConfig = function (input) {
    var _a;
    var useDualstackEndpoint = normalizeBoolean(input.useDualstackEndpoint);
    var endpoint = input.endpoint, useFipsEndpoint = input.useFipsEndpoint;
    return __assign(__assign({}, input), { tls: (_a = input.tls) !== null && _a !== void 0 ? _a : true, endpoint: endpoint
            ? normalizeEndpoint(__assign(__assign({}, input), { endpoint: endpoint }))
            : function () { return getEndpointFromRegion(__assign(__assign({}, input), { useDualstackEndpoint: useDualstackEndpoint, useFipsEndpoint: useFipsEndpoint })); }, isCustomEndpoint: endpoint ? true : false, useDualstackEndpoint: useDualstackEndpoint });
};
