import { __assign, __awaiter, __generator } from "tslib";
import { SignatureV4 } from "@aws-sdk/signature-v4";
var S3SignatureV4 = (function () {
    function S3SignatureV4(options) {
        this.sigv4Signer = new SignatureV4(options);
        this.signerOptions = options;
    }
    S3SignatureV4.prototype.sign = function (requestToSign, options) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (options.signingRegion === "*") {
                    if (this.signerOptions.runtime !== "node")
                        throw new Error("This request requires signing with SigV4Asymmetric algorithm. It's only available in Node.js");
                    return [2, this.getSigv4aSigner().sign(requestToSign, options)];
                }
                return [2, this.sigv4Signer.sign(requestToSign, options)];
            });
        });
    };
    S3SignatureV4.prototype.presign = function (originalRequest, options) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (options.signingRegion === "*") {
                    if (this.signerOptions.runtime !== "node")
                        throw new Error("This request requires signing with SigV4Asymmetric algorithm. It's only available in Node.js");
                    return [2, this.getSigv4aSigner().presign(originalRequest, options)];
                }
                return [2, this.sigv4Signer.presign(originalRequest, options)];
            });
        });
    };
    S3SignatureV4.prototype.getSigv4aSigner = function () {
        if (!this.sigv4aSigner) {
            var CrtSignerV4_1;
            try {
                CrtSignerV4_1 = require("@aws-sdk/signature-v4-crt").CrtSignerV4;
                if (typeof CrtSignerV4_1 !== "function")
                    throw new Error();
            }
            catch (e) {
                e.message =
                    e.message + "\nPlease check if you have installed \"@aws-sdk/signature-v4-crt\" package explicitly. \n" +
                        "For more information please go to https://github.com/aws/aws-sdk-js-v3#known-issues";
                throw e;
            }
            this.sigv4aSigner = new CrtSignerV4_1(__assign(__assign({}, this.signerOptions), { signingAlgorithm: 1 }));
        }
        return this.sigv4aSigner;
    };
    return S3SignatureV4;
}());
export { S3SignatureV4 };
