import { createElement } from "react";
import { FluentContext } from "./context.js";
/**
 * The Provider component for the `ReactLocalization` class.
 *
 * Exposes a `ReactLocalization` instance to all descendants via React's
 * context feature.  It makes translations available to all localizable
 * elements in the descendant's render tree without the need to pass them
 * explicitly.
 *
 * `LocalizationProvider` takes an instance of `ReactLocalization` in the
 * `l10n` prop. This instance will be made available to `Localized` components
 * under the provider.
 *
 * @example
 * ```jsx
 * <LocalizationProvider l10n={…}>
 *     …
 * </LocalizationProvider>
 * ```
 */
export function LocalizationProvider(props) {
    return createElement(FluentContext.Provider, { value: props.l10n }, props.children);
}
