import { __values } from "tslib";
import { HttpResponse } from "@aws-sdk/protocol-http";
import { buildQueryString } from "@aws-sdk/querystring-builder";
import { requestTimeout } from "./request-timeout";
var FetchHttpHandler = (function () {
    function FetchHttpHandler(_a) {
        var _b = _a === void 0 ? {} : _a, requestTimeout = _b.requestTimeout;
        this.requestTimeout = requestTimeout;
    }
    FetchHttpHandler.prototype.destroy = function () {
    };
    FetchHttpHandler.prototype.handle = function (request, _a) {
        var _b = _a === void 0 ? {} : _a, abortSignal = _b.abortSignal;
        var requestTimeoutInMs = this.requestTimeout;
        if (abortSignal === null || abortSignal === void 0 ? void 0 : abortSignal.aborted) {
            var abortError = new Error("Request aborted");
            abortError.name = "AbortError";
            return Promise.reject(abortError);
        }
        var path = request.path;
        if (request.query) {
            var queryString = buildQueryString(request.query);
            if (queryString) {
                path += "?" + queryString;
            }
        }
        var port = request.port, method = request.method;
        var url = request.protocol + "//" + request.hostname + (port ? ":" + port : "") + path;
        var body = method === "GET" || method === "HEAD" ? undefined : request.body;
        var requestOptions = {
            body: body,
            headers: new Headers(request.headers),
            method: method,
        };
        if (typeof AbortController !== "undefined") {
            requestOptions["signal"] = abortSignal;
        }
        var fetchRequest = new Request(url, requestOptions);
        var raceOfPromises = [
            fetch(fetchRequest).then(function (response) {
                var e_1, _a;
                var fetchHeaders = response.headers;
                var transformedHeaders = {};
                try {
                    for (var _b = __values(fetchHeaders.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var pair = _c.value;
                        transformedHeaders[pair[0]] = pair[1];
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                var hasReadableStream = response.body !== undefined;
                if (!hasReadableStream) {
                    return response.blob().then(function (body) { return ({
                        response: new HttpResponse({
                            headers: transformedHeaders,
                            statusCode: response.status,
                            body: body,
                        }),
                    }); });
                }
                return {
                    response: new HttpResponse({
                        headers: transformedHeaders,
                        statusCode: response.status,
                        body: response.body,
                    }),
                };
            }),
            requestTimeout(requestTimeoutInMs),
        ];
        if (abortSignal) {
            raceOfPromises.push(new Promise(function (resolve, reject) {
                abortSignal.onabort = function () {
                    var abortError = new Error("Request aborted");
                    abortError.name = "AbortError";
                    reject(abortError);
                };
            }));
        }
        return Promise.race(raceOfPromises);
    };
    return FetchHttpHandler;
}());
export { FetchHttpHandler };
