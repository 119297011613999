import adjacencyGraphs from './adjacencyGraphs.json'
import passwords from './passwords.json'

export default {
  dictionary: {
    passwords,
  },

  adjacencyGraphs,
}
