import { useContext } from "react";
import { FluentContext } from "./context.js";
export const useLocalization = () => {
    const l10n = useContext(FluentContext);
    if (!l10n) {
        throw new Error("useLocalization was used without wrapping it in a " +
            "<LocalizationProvider />.");
    }
    return { l10n };
};
