import { booleanSelector, SelectorType } from "@aws-sdk/util-config-provider";
export var NODE_DISABLE_MULTIREGION_ACCESS_POINT_ENV_NAME = "AWS_S3_DISABLE_MULTIREGION_ACCESS_POINTS";
export var NODE_DISABLE_MULTIREGION_ACCESS_POINT_INI_NAME = "s3_disable_multiregion_access_points";
export var NODE_DISABLE_MULTIREGION_ACCESS_POINT_CONFIG_OPTIONS = {
    environmentVariableSelector: function (env) {
        return booleanSelector(env, NODE_DISABLE_MULTIREGION_ACCESS_POINT_ENV_NAME, SelectorType.ENV);
    },
    configFileSelector: function (profile) {
        return booleanSelector(profile, NODE_DISABLE_MULTIREGION_ACCESS_POINT_INI_NAME, SelectorType.CONFIG);
    },
    default: false,
};
